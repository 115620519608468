import throttle from 'lodash/throttle';
import { logger } from '@leon-hub/logging';
import { isFunction, isNullOrUndefined } from '@leon-hub/guards';
import { Json, requestIdleCallback } from '@leon-hub/utils';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useAnalytics } from 'web/src/modules/analytics/composables';
var CollectMethod;
(CollectMethod || (CollectMethod = {}))["ClickCounter"] = "click-counter";
var CollectMethodGroup;
(CollectMethodGroup || (CollectMethodGroup = {}))["ClickCounter"] = "clickCounter";
const directiveName = 'collectClickDirective';
const empiricalThrottleTimeMs = 150;
const listeners = new Map();
const datasetCollectKey = 'collectKey';
const isEventHandler = (value)=>isFunction(value);
const mapToAnalyticsEvent = (method, payload)=>{
    let groupName = null;
    let eventName = null;
    if ("click-counter" === method) {
        groupName = "clickCounter";
        eventName = AnalyticsEvent.CLICK_MAP;
    }
    return {
        name: eventName,
        payload: {
            [groupName]: {
                ...payload
            }
        }
    };
};
const attachListener = (element, handler)=>{
    const listenerKey = element.dataset[datasetCollectKey];
    listeners.set(listenerKey, handler);
    element.addEventListener('click', handler);
};
const detachListener = (element)=>{
    const key = element.dataset[datasetCollectKey];
    const handler = listeners.get(key);
    isEventHandler(handler);
    element.removeEventListener('click', handler);
};
function getDirective() {
    return {
        beforeMount (element, binding) {
            if (isNullOrUndefined(element)) return;
            const elementToListen = element;
            // eslint-disable-next-line unicorn/prefer-includes
            Object.values(CollectMethod).some((item)=>item === binding.arg);
            const eventData = mapToAnalyticsEvent("click-counter", binding.value);
            const eventDataKey = Json.stringify(eventData, {
                defaultValue: null
            });
            isNullOrUndefined(eventDataKey);
            elementToListen.dataset[datasetCollectKey] = eventDataKey;
            const handler = ()=>requestIdleCallback.bind(null, useAnalytics().push.bind(null, eventData.name, eventData.payload))();
            attachListener(elementToListen, binding?.modifiers.throttle ? throttle(handler, empiricalThrottleTimeMs) : handler);
        },
        beforeUnmount (element) {
            if (element) detachListener(element);
            else logger.warn(`[${directiveName}:unbind] HTMLElement should be exist.`);
        }
    };
}
const CollectClickPlugin = {
    install (app) {
        app.directive('collect', getDirective());
    }
};
export default CollectClickPlugin;
