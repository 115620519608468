import getInstanceClient from './instance/client';
import { Events, TargetParamEnter, TargetParamNavigation, TargetParamHistory, TargetParamWithdrawal } from './constants';
const provideInstance = ()=>getInstanceClient();
let instance = null;
export function getYMInstance() {
    if (instance) return instance;
    instance = provideInstance();
    return instance;
}
export { Events, TargetParamEnter, TargetParamNavigation, TargetParamHistory, TargetParamWithdrawal };
