import { _ as _define_property } from "@swc/helpers/_/_define_property";
function visitSportEvent(sportsEventObject) {
    window.srtmCommands?.push({
        event: 'srt.retargeting',
        payload: {
            sportsEvent: sportsEventObject
        }
    });
}
let SportRadarService = class SportRadarService {
    static getInstance() {
        if (!SportRadarService.instance) SportRadarService.instance = new SportRadarService();
        return SportRadarService.instance;
    }
    setPixelManager(pixelManager) {
        const isNotConfigured = pixelManager && !this.isInitiated;
        if (isNotConfigured && pixelManager) {
            this.evalString(pixelManager);
            this.isInitiated = true;
        }
    }
    constructor(){
        _define_property(this, "isInitiated", false);
        // eslint-disable-next-line class-methods-use-this
        _define_property(this, "evalString", (command)=>{
            window.eval(command);
        });
        _define_property(this, "visitSportEvent", visitSportEvent);
    }
};
_define_property(SportRadarService, "instance", void 0);
export { SportRadarService as default };
