import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika/src/constants';
import { getYMInstance } from '@leon-hub/yandex-metrika';
import RouteName from '@leon-hub/routing-config';
import BaseRouteName from '@leon-hub/routing-config-names/src/BaseRouteName';
import { TargetParamEnter, TargetParamNavigation } from '@leon-hub/yandex-metrika/src/constants/targetParams';
import GoogleService from 'web/src/modules/analytics/services/googleService';
import ZetaDspService from 'web/src/modules/analytics/services/zetaDspService';
import { captchaChallengeWasShownKey } from 'web/src/modules/captcha/store/constants';
import SportRadarService from 'web/src/modules/analytics/services/sportRadarService';
import registrationTypeFormat from 'web/src/modules/registration/utils/registrationTypeFormat';
export default function useAnalytics() {
    function push(event, data) {
        getYMInstance().triggerEvent(event, data);
    }
    function clickMap(payload) {
        push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: payload
        });
    }
    function pushGTM(event, data) {
        GoogleService.getInstance().pushEvent(event, data);
    }
    return {
        push,
        clickMap,
        pushGTM,
        sendModalErrorMessage (dialog) {
            if (dialog.analyticsModalType) {
                const { analyticsModalType, confirmMessage } = dialog;
                push(AnalyticsEvent.MODAL_ERROR_MESSAGES, {
                    [String(analyticsModalType)]: confirmMessage
                });
            }
        },
        phoneCall () {
            push(AnalyticsEvent.Z_OPEN_SUPPORT_PAGE, {
                supportDetails: {
                    callSupport: {
                        clickPhoneNumber: true
                    }
                }
            });
        },
        // eslint-disable-next-line sonarjs/cognitive-complexity
        navigationItemClick (navigationItem, isLogin) {
            let component = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 'default';
            // TODO: switch case
            if (navigationItem.routeName === RouteName.PROMOTIONS && component === String("BaseMenu")) clickMap({
                promo: 'sideMenuPromo'
            });
            if ("AFFILIATE_PROGRAM_WEB2" === navigationItem.routeName || navigationItem.routeName === BaseRouteName.AFFILIATE_PROGRAM) clickMap({
                affiliateProgramLink: true
            });
            // eslint-disable-next-line max-len
            if (navigationItem.routeName === RouteName.DEPOSITS && navigationItem.id === String("sidebar-left-deposits")) clickMap({
                deposit: 'sideMenuDeposit'
            });
            else if (navigationItem.routeName === RouteName.DEPOSITS) clickMap({
                deposit: 'bottomLowBalance'
            });
            if (navigationItem.routeName === RouteName.DEPOSITS && !isLogin && navigationItem.id !== String("sidebar-left-deposits")) clickMap({
                enter: TargetParamEnter.BOTTOM_LOW_BALANCE
            });
            if (navigationItem.routeName === RouteName.PROFILE && !isLogin) clickMap({
                enter: TargetParamEnter.BOTTOM_LOW_PROFILE
            });
            if (navigationItem.routeName === RouteName.PROMOTIONS && navigationItem.id === String("generic-promotions")) clickMap({
                promo: 'footer'
            });
            if (navigationItem.routeName === RouteName.PROMOTIONS && navigationItem.id === String("top-promotions")) clickMap({
                promo: 'tab'
            });
            "1";
            switch(navigationItem.props?.metrics){
                case TargetParamNavigation.BUTTON_LOW_SLOTS:
                    clickMap({
                        SLOTS: TargetParamNavigation.BUTTON_LOW_SLOTS
                    });
                    break;
                case TargetParamNavigation.SIDE_MENU_SLOTS:
                    clickMap({
                        SLOTS: TargetParamNavigation.SIDE_MENU_SLOTS
                    });
                    break;
                case TargetParamNavigation.HEADER_SLOTS:
                    clickMap({
                        SLOTS: TargetParamNavigation.HEADER_SLOTS
                    });
                    break;
                default:
                    break;
            }
            "1";
            switch(navigationItem.props?.metrics){
                case TargetParamNavigation.SIDE_MENU_LIVE:
                    clickMap({
                        LIVE: TargetParamNavigation.SIDE_MENU_LIVE
                    });
                    break;
                case TargetParamNavigation.HEADER_LIVE:
                    clickMap({
                        LIVE: TargetParamNavigation.HEADER_LIVE
                    });
                    break;
                default:
                    break;
            }
            "1";
            switch(navigationItem.props?.metrics){
                case TargetParamNavigation.SIDE_MENU_VIRTUAL_SPORTS:
                    clickMap({
                        virtualSports: TargetParamNavigation.SIDE_MENU_VIRTUAL_SPORTS
                    });
                    break;
                case TargetParamNavigation.HEADER_VIRTUAL_SPORTS:
                    clickMap({
                        virtualSports: TargetParamNavigation.HEADER_VIRTUAL_SPORTS
                    });
                    break;
                default:
                    break;
            }
            if (navigationItem.routeName === RouteName.VIP) {
                if (navigationItem.id === String("generic-vip")) push(AnalyticsEvent.Z_CLICK_TO_OPEN_VIP, {
                    vip_program: {
                        click_to_open: 'footer'
                    }
                });
                if (navigationItem.id === String("top-vip")) push(AnalyticsEvent.Z_CLICK_TO_OPEN_VIP, {
                    vip_program: {
                        click_to_open: 'header'
                    }
                });
                if (navigationItem.id === String("sidebar-left-tab-item-landing-vip")) push(AnalyticsEvent.Z_CLICK_TO_OPEN_VIP, {
                    vip_program: {
                        click_to_open: 'sidebar'
                    }
                });
            }
        },
        makeDeposit (depositMetrics) {
            push(AnalyticsEvent.Z_MAKE_DEPOSITE, {
                deposits: {
                    ok: {
                        paymentSystemId: `dep${depositMetrics.paymentSystemId.toLowerCase()}`,
                        firstDeposit: depositMetrics.firstDeposit
                    }
                }
            });
            const { systemAmount, paymentId, userId, firstTheme, theme } = depositMetrics;
            const googleMetricsData = {
                ...depositMetrics,
                amount: systemAmount,
                transactionId: paymentId
            };
            if (depositMetrics.firstDeposit) {
                GoogleService.getInstance().pushEvent('firstDeposit', googleMetricsData);
                push(AnalyticsEvent.Z_AB_TEST_NEW_USERS, {
                    ab_test_light_mode: {
                        [firstTheme.toLowerCase()]: {
                            first_deposit: theme.toLowerCase()
                        }
                    }
                });
                ZetaDspService.getInstance().firstDeposit({
                    systemAmount,
                    paymentId,
                    userId
                });
            } else {
                GoogleService.getInstance().pushEvent('makeDeposit', googleMetricsData);
                ZetaDspService.getInstance().makeDeposit({
                    systemAmount,
                    paymentId,
                    userId
                });
            }
        },
        registrationStart () {
            GoogleService.getInstance().pushEvent('customerRegistrationStart', {});
        },
        registrationSuccess (registrationMetrics) {
            const { userId, registrationType, firstTheme, theme } = registrationMetrics;
            // @ts-ignore
            GoogleService.getInstance().pushEvent('customerRegistered', registrationMetrics);
            push(AnalyticsEvent.Z_AB_TEST_NEW_USERS, {
                ab_test_light_mode: {
                    [firstTheme.toLowerCase()]: {
                        registration: theme.toLowerCase()
                    }
                }
            });
            if (registrationType) push(AnalyticsEvent.Z_LI_REGISTRATION_OK, {
                registration: {
                    [registrationTypeFormat(registrationType)]: 'ok'
                }
            });
            ZetaDspService.getInstance().customerRegistered({
                userId
            });
        },
        loginSuccess (options) {
            GoogleService.getInstance().pushEvent('login', {
                options
            });
            push(AnalyticsEvent.Z_LOGGED_IN, {
                login: {
                    ok: {
                        [options.loginTab]: {
                            captchaChallengeWasShown: window.sessionStorage.getItem(captchaChallengeWasShownKey),
                            captcha: options.captchaType,
                            loginType: options.loginMethod
                        }
                    }
                }
            });
        },
        yandexZInit (initOptions) {
            const platform = "web";
            push(AnalyticsEvent.Z_INIT, {
                userId: initOptions.userId,
                customerType: initOptions.customerType || 'normal',
                country: initOptions.country,
                type: {
                    [platform]: "desktop"
                },
                webVersion: "6.100.0",
                ...initOptions.currentTheme ? {
                    mode: initOptions.currentTheme
                } : {}
            });
        },
        // eslint-disable-next-line sonarjs/cognitive-complexity
        visitSportEvent (options) {
            const { markets, ...parameters } = options;
            const primaryMarket = markets.find((market)=>market.isPrimary);
            if (primaryMarket) {
                const { runners } = primaryMarket;
                const isDrawPossible = runners.length > 2;
                for (const [index, runner] of runners.entries()){
                    const { value } = runner;
                    if (0 === index) parameters.oddsHome = value;
                    if (1 === index) {
                        if (isDrawPossible) parameters.oddsDraw = value;
                        else parameters.oddsAway = value;
                    }
                    if (2 === index) parameters.oddsAway = value;
                }
            }
            const sportEventOptions = {
                match_id: parameters.eventId,
                sr_match_id: '',
                team_home_name: parameters.home,
                team_home_name_short: parameters.homeShort || '',
                team_home_logo: parameters.homeLogo || '',
                team_away_name: parameters.away,
                team_away_name_short: parameters.awayShort || '',
                team_away_logo: parameters.awayLogo || '',
                scheduled: parameters.scheduled,
                status: parameters.status,
                tournament_name: parameters.tournamentName,
                sport: parameters.sport,
                odds_home: parameters.oddsHome || '',
                odds_draw: parameters.oddsDraw || '',
                odds_away: parameters.oddsAway || '',
                language: parameters.language
            };
            GoogleService.getInstance().pushEvent('sportEvent', {
                sportEvent: sportEventOptions
            });
            SportRadarService.getInstance().visitSportEvent(sportEventOptions);
        },
        pushEgsGameEvent (options) {
            GoogleService.getInstance().pushEvent('game', {
                options
            });
        }
    };
}
