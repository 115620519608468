const deserializeNumber = (value)=>{
    const number = parseInt(value, 10);
    return Number.isNaN(number) ? value : number;
};
const deserializeBoolean = (value)=>'false' === value || 'true' === value ? JSON.parse(value) : value;
const deserializeValue = (value)=>{
    const result = deserializeNumber(value);
    return 'string' == typeof result ? deserializeBoolean(result) : result;
};
export default function parseMetricsToolConfig(config) {
    const result = {};
    for (const { key, value } of config || [])result[key] = deserializeValue(value);
    return result;
}
