/* eslint-disable no-console */ const prefix = '[yandex-metrika]';
const info = function() {
    let message = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : '', parameters = arguments.length > 1 ? arguments[1] : void 0;
    /* eslint-disable-next-line no-console */ console.info(`${prefix} ${message}`, parameters || '');
};
const error = function() {
    let message = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : '', parameters = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    /* eslint-disable-next-line no-console */ console.error(`${prefix} ${message}`, parameters);
};
const silent = function() {
    "1";
    for(var _len = arguments.length, parameters = new Array(_len), _key = 0; _key < _len; _key++){
        parameters[_key] = arguments[_key];
    }
    /* eslint-disable-next-line no-console */ console.info(prefix, parameters);
};
export default {
    info,
    error,
    silent
};
