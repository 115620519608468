let EVENTS_QUEUE = [];
const addEvent = (event)=>EVENTS_QUEUE.push(event);
const getEvents = ()=>[
        ...EVENTS_QUEUE
    ];
const clear = ()=>{
    EVENTS_QUEUE = [];
};
const run = (callback)=>{
    for (const { eventName, parameters } of EVENTS_QUEUE)callback(eventName, parameters);
    clear();
};
export default {
    addEvent,
    getEvents,
    run,
    clear
};
