import EventsQueue from './queues/events';
import ParametersQueue from './queues/parameters';
import debug from '../services/debug';
import loadAppendScript from '../services/loadAppendScript';
import { defaultOptions } from '../constants';
let INSTANCE = null;
let OPTIONS = defaultOptions;
const mergeOptions = (options)=>({
        ...defaultOptions,
        ...options
    });
const setDefaultOptions = (options)=>{
    OPTIONS = {
        ...OPTIONS,
        ...options
    };
};
const triggerEvent = function(eventName) {
    let parameters = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    if (INSTANCE) {
        if (OPTIONS.developMode) debug.info(`trigger event ${eventName}:`, parameters);
        else INSTANCE.reachGoal(eventName, parameters);
    } else if (OPTIONS.collectQueues) EventsQueue.addEvent({
        eventName,
        parameters
    });
};
const triggerParameters = (parameters)=>{
    if (INSTANCE) {
        if (OPTIONS.developMode) debug.info('trigger params:', parameters);
        else INSTANCE.params(parameters);
    } else if (OPTIONS.collectQueues) ParametersQueue.addParameter(parameters);
};
const createYandexMetrika = async (sourceUrl, props)=>{
    await loadAppendScript(sourceUrl);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const YaMetrika = window.Ya.Metrika2;
    return new YaMetrika(props);
};
const register = async function(config) {
    let options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    if (INSTANCE) debug.error('instance already exist');
    OPTIONS = mergeOptions(options);
    const { sourceUrl = defaultOptions.sourceUrl } = OPTIONS;
    INSTANCE = await createYandexMetrika(sourceUrl, config);
    if (OPTIONS.developMode) debug.info('registration success');
    if (OPTIONS.collectQueues) {
        EventsQueue.run(triggerEvent);
        ParametersQueue.run(triggerParameters);
    }
};
const unregister = ()=>{
    EventsQueue.clear();
    ParametersQueue.clear();
    INSTANCE = null;
};
export default function getInstanceClient() {
    return {
        register,
        unregister,
        triggerEvent,
        setDefaultOptions,
        triggerParams: triggerParameters,
        getEvents: EventsQueue.getEvents,
        getParams: ParametersQueue.getParameters
    };
}
