import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { ZetaDcpConfigType } from '@leon-hub/api-sdk/src/sdk/sdk';
import { logger as loggerInstance } from '@leon-hub/logging';
const logger = loggerInstance.createNamespace('ZetaDspService');
let ZetaDspService = class ZetaDspService {
    setDefaultOptions(defaultOptions) {
        this.zetaDSPDefaultOptions = defaultOptions;
    }
    static getInstance() {
        if (!ZetaDspService.instance) ZetaDspService.instance = new ZetaDspService();
        return ZetaDspService.instance;
    }
    sendDepositParams(metrics, initArguments) {
        if (initArguments) this.setArgsAndTrack({
            cur: 'usd',
            leadid: metrics.userId,
            price: metrics.systemAmount,
            revenue: metrics.systemAmount,
            transid: metrics.paymentId,
            ...initArguments
        });
    }
    makeDeposit(metrics) {
        this.sendDepositParams(metrics, this.zetaDSPDefaultOptions[ZetaDcpConfigType.DEPOSIT]);
    }
    firstDeposit(metrics) {
        this.sendDepositParams(metrics, this.zetaDSPDefaultOptions[ZetaDcpConfigType.FIRST_TIME_DEPOSIT]);
    }
    customerRegistered(parameters) {
        this.setArgsAndTrack({
            ...this.zetaDSPDefaultOptions[ZetaDcpConfigType.REGISTRATION],
            ...parameters
        });
    }
    setArgsAndTrack(extraArguments) {
        if (!this.zetaDSPDefaultOptions) {
            logger.error('ZetaDspService is trying to send metrics without default options', extraArguments);
            return;
        }
        const allArguments = {
            ...this.zetaDSPDefaultOptions[ZetaDcpConfigType.ALL],
            ...extraArguments
        };
        // eslint-disable-next-line no-underscore-dangle
        if ('function' != typeof window._rfi) // eslint-disable-next-line no-underscore-dangle
        window._rfi = function rfi() {
            // eslint-disable-next-line no-underscore-dangle
            window._rfi.commands = window._rfi.commands || [];
            // eslint-disable-next-line no-underscore-dangle,prefer-rest-params
            window._rfi.commands.push(arguments);
        };
        const argumentsKey = Object.keys(allArguments);
        for (const key of argumentsKey)// eslint-disable-next-line no-underscore-dangle
        window._rfi('setArgs', key, allArguments[key]);
        // eslint-disable-next-line no-underscore-dangle
        window._rfi('track');
    }
    constructor(){
        _define_property(this, "zetaDSPDefaultOptions", {});
    }
};
_define_property(ZetaDspService, "instance", void 0);
export { ZetaDspService as default };
