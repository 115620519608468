const loadAppendScript = (sourceUrl)=>new Promise((resolve, reject)=>{
        if (!window.yandex_metrika_callbacks2) window.yandex_metrika_callbacks2 = [];
        window.yandex_metrika_callbacks2.push(()=>{
            resolve();
        });
        const head = document.head || document.querySelectorAll('head')[0];
        const script = document.createElement('script');
        script.src = sourceUrl;
        // script.async = true;
        script.defer = true;
        // script.addEventListener('load', () => resolve());
        script.addEventListener('error', (error)=>reject(error));
        head.append(script);
    });
export default loadAppendScript;
