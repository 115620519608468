let PARAMS_QUEUE = [];
const addParameter = (parameters)=>PARAMS_QUEUE.push(parameters);
const getParameters = ()=>[
        ...PARAMS_QUEUE
    ];
const clear = ()=>{
    PARAMS_QUEUE = [];
};
const run = (callback)=>{
    for (const parameters of PARAMS_QUEUE)callback(parameters);
    clear();
};
export default {
    addParameter,
    getParameters,
    run,
    clear
};
