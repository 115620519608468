import { requestIdleCallback } from '@leon-hub/utils';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import CollectClickPlugin from './directives/CollectClickPlugin';
import { initYM, initSportRadar, initGoogleAnalytics, initZetaDSP } from './utils';
const initTimeout = 4000;
const AnalyticsPlugin = {
    install (app) {
        // TODO: Remove from container - Plugins must not depend on container
        // eslint-disable-next-line no-param-reassign
        app.config.globalProperties.$ymUtils = {
            phoneCall () {
                useAnalytics().phoneCall();
            }
        };
        app.use(CollectClickPlugin);
        const initYandexMetrika = ()=>{
            initYM({
                initTimeout
            });
        };
        const initZetaDSPTag = ()=>{
            initZetaDSP();
        };
        const initGM = ()=>{
            initGoogleAnalytics();
        };
        requestIdleCallback(initYandexMetrika);
        requestIdleCallback(initZetaDSPTag, {
            timeout: initTimeout
        });
        requestIdleCallback(initSportRadar(), {
            timeout: initTimeout
        });
        requestIdleCallback(initGM, {
            timeout: initTimeout
        });
    }
};
export default AnalyticsPlugin;
