import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { ScriptElement } from '@leon-hub/utils';
let GoogleService = class GoogleService {
    // eslint-disable-next-line class-methods-use-this
    async initGoogle() {
        let id = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : '';
        const gtmContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${id}');`;
        await new ScriptElement({
            content: gtmContent,
            name: 'gtm0',
            appendTarget: 'head'
        }).addScript();
        await new ScriptElement({
            content: `function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${id}');`,
            name: 'gtm1',
            appendTarget: 'head'
        }).addScript();
    }
    static getInstance() {
        if (!GoogleService.instance) GoogleService.instance = new GoogleService();
        return GoogleService.instance;
    }
    setCounterId(counterId) {
        const isNotConfigured = !!counterId && !this.isInitiated;
        if (isNotConfigured) {
            this.initGoogle(counterId);
            this.isInitiated = true;
        }
    }
    // eslint-disable-next-line class-methods-use-this
    pushEvent(eventName, data) {
        window.dataLayer?.push({
            event: eventName,
            ...data
        });
    }
    constructor(){
        _define_property(this, "isInitiated", false);
        window.dataLayer = window.dataLayer || [];
    }
};
_define_property(GoogleService, "instance", void 0);
export { GoogleService as default };
